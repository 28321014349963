import React from "react";
import styles from "./TopAnime.module.scss";
import atakatyt from "../../images/atakatytaniv.jpg";
import naruto from "../../images/naruto.jpg";
import deathNote from "../../images/deathNote.jpg";
import castle from "../../images/castle.jpg";
import academy from "../../images/academy.png";
import witcher from "../../images/witcher.jpeg";
import fabric from "../../images/fabric.png";
import { useNavigate } from "react-router-dom";

const TopAnime = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/watch/${id}`);
    console.log(id);
  };

  const homePageContent = {
    title: "Ласкаво просимо до AnimeWorld",
    banner: {
      headline: "Відкрий для себе найкраще аніме!",
      subheadline:
        "Досліджуй топові аніме, останні новинки та улюблені серіали спільноти.",
      backgroundImage: "url('/images/banner.jpg')",
    },
    featuredAnime: [
      {
        title: "Атака Титанів",
        description:
          "Слідкуйте за драматичною історією боротьби людства з гігантськими істотами, відомими як Титани.",
        image: atakatyt,
        index: "AtackTitans",
      },

      {
        title: "Фабрика иллюзий Алисы и Терезы",
        description:
          "У світі, де майже всі мають суперсили, слідкуй за пригодами юного героя Ізуку Мідорії.",
        image: fabric,
        index: "Fabric",
      },
      {
        title: "Ходячий замок",
        description:
          "Слідкуйте за драматичною історією боротьби людства з гігантськими істотами, відомими як Титани.",
        image: castle,
        index: "Castle",
      },
      {
        title: "Наруто",
        description:
          "Історія юного ніндзя, що прагне стати найсильнішим та захистити своє село.",
        image: naruto,
        index: "Naruto",
      },
      {
        title: "Моя Геройська Академія",
        description:
          "У світі, де майже всі мають суперсили, слідкуй за пригодами юного героя Ізуку Мідорії.",
        image: academy,
        index: "Academy",
      },
      {
        title: "Ведьмак. Кошмар волка",
        description:
          "Історія юного ніндзя, що прагне стати найсильнішим та захистити своє село.",
        image: witcher,
        index: "Witcher",
      },
      {
        title: "Death Note",
        description:
          "У світі, де майже всі мають суперсили, слідкуй за пригодами юного героя Ізуку Мідорії.",
        image: deathNote,
        index: "DeathNote",
      },
    ],
    latestNews: {
      title: "Останні новини",
      articles: [
        {
          headline: "Новий сезон «Атака Титанів» оголошено!",
          date: "20 вересня 2024",
          summary:
            "Новий сезон одного з найпопулярніших аніме стартує вже цього року! Готуйтеся до нових епічних битв!",
        },
        {
          headline: "Нова манга від творців «Наруто»!",
          date: "15 вересня 2024",
          summary:
            "Творці «Наруто» анонсували новий проект, який обіцяє здивувати фанатів неймовірними сюжетними поворотами.",
        },
      ],
    },
  };
  return (
    <section className={styles.topAnimeSection}>
      <h2>Top Anime</h2>
      <div className={styles.topAnimeItems}>
        {homePageContent.featuredAnime.map((item, idx) => (
          <div
            className={styles.topAnimeItem}
            onClick={() => handleClick(item.index)}
            key={idx}
          >
            <img src={item.image} alt="" />
            <h3>{item.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TopAnime;
