import React from "react";
import { CgSearch } from "react-icons/cg";
import styles from "./Search.module.scss";

const Search = () => {
  return (
    <div className={styles.headerSearch}>
      <input type="text" placeholder="Search..." />
      <span>
        <CgSearch size={20} color="#F3F4F7" />
      </span>
    </div>
  );
};

export default Search;
