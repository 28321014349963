import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      const checkAuth = async () => {
        setLoading(true);
        try {
          const response = await axios.post(
            "http://127.0.0.1:8000/api/auth/",
            {},
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (response.status === 200) {
            setIsAuthenticated(true);
            setUserName(response.data.user.nickname);
          }
        } catch (error) {
          console.log("Token verification failed:", error);
          setIsAuthenticated(false);
        } finally {
          setLoading(false);
        }
      };

      checkAuth();
    } else {
      setLoading(false);
      setIsAuthenticated(false);
    }
  }, [localStorage.getItem("access_token")]);

  const memoizedAuthState = useMemo(() => {
    return { isAuthenticated, userName, loading };
  }, [isAuthenticated, userName, loading]);

  return memoizedAuthState;
};
