import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import styles from "./Profile.module.scss";

const Profile = () => {
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        setError("No access token found");
        return;
      }

      try {
        const response = await axios.get("http://127.0.0.1:8000/api/profile/", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          console.log(response);
          setProfileData(response.data);
        }
      } catch (error) {
        console.log("Token verification failed:", error);
        setError(error);
      }
    };

    if (isAuthenticated) {
      checkAuth();
    }
  }, [isAuthenticated]);

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <section>
      {isAuthenticated && profileData ? (
        <div className={styles.profilePage}>
          <h2>Watched Anime</h2>
          {profileData.watched_anime.map((item, idx) => (
            <div key={idx}>
              {item.title}
              <img src={`http://127.0.0.1:8000${item.image}`} alt="Anime" />
            </div>
          ))}
        </div>
      ) : (
        <p>You need to Log In</p>
      )}
    </section>
  );
};

export default Profile;
