import React, { useState } from "react";
import styles from "./Register.module.scss";
import axios from "axios";
import { useModal } from "../../context/ModalContext";

const Register = ({ onClose, onSwitchToLogin }) => {
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [profilePicture, setProfilePicture] = useState("1");
  const { switchToLogin, closeModals } = useModal();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== password_confirmation) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post("http://127.0.0.1:8000/api/register/", {
        nickname,
        email,
        password,
        password_confirmation,
        profilePicture,
      });
      console.log(response.data);

      const { access, refresh, user } = response.data;

      if (access && refresh) {
        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);

        onClose();
      }

      console.log(localStorage.access_token);
    } catch (error) {
      if (error.response && error.response.data) {
        const { nickname, email } = error.response.data;

        if (nickname && nickname.length > 0) {
          setError(nickname[0]);
        } else if (email && email.length > 0) {
          setError(email[0]);
        } else {
          setError("Unknown error occurred");
        }
      } else {
        console.log(error.message);
        setError(error.message || "Unknown error occurred");
      }
    }
    console.log(error);
  };

  return (
    <div className={styles.headerRegister}>
      <button onClick={onClose} className={styles.buttonExit}>
        ×
      </button>
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit} className={styles.headerForm}>
        <label htmlFor="nickname">Username</label>
        <input
          id="nickname"
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <label htmlFor="password2">Confirm Password</label>
        <input
          id="password2"
          type="password"
          value={password_confirmation}
          onChange={(e) => setPassword2(e.target.value)}
        />

        <label htmlFor="profilePicture">Profile Picture</label>
        <button type="button">Choose image</button>
        {error && <p className={styles.registerError}>{error}</p>}
        <button type="submit">Sign up</button>
        <button className={styles.registerLoginButton} onClick={switchToLogin}>
          Log in
        </button>
      </form>
    </div>
  );
};

export default Register;
