import React from "react";
import styles from "./Footer.module.scss";
import Search from "../Search/Search";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>&copy; 2024 AnimeStream. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
