import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Main from "./pages/main/Main";
import Profile from "./pages/profile/Profile";
import NotFound from "./pages/notfound/NotFound";
import WelcomePage from "./pages/welcomePage/WelcomePage";
import TopAnime from "./components/TopAnime/TopAnime";
import WatchingPage from "./pages/watchingPage/WatchingPage";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/watch/:id" element={<WatchingPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
