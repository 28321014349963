import React from "react";
import styles from "./SugestedAnime.module.scss";
import narutoBG from "../../images/naruto.jfif";
import { Link } from "react-router-dom";

const SugestedAnime = ({ loading }) => {
  return loading ? (
    <div className={styles.sugestedAnime}></div>
  ) : (
    <section className={styles.sugestedAnime}>
      <div className={styles.sugestedAnimeText}>
        <h1>Naruto</h1>
        <p>
          Immerse yourself in the world of anime! Discover the latest releases,
          fan-favorite series, and exclusive content all in one place.
        </p>
        <div className={styles.SugestedAnimePageButtons}>
          <button>
            <Link to={"/watch/Naruto"}>Watch</Link>
          </button>
          <button>Info</button>
        </div>
      </div>
      <div className={styles.sugestedAnimeImage}>
        <img src={narutoBG} alt="Naruto Background" />
      </div>
    </section>
  );
};

export default SugestedAnime;
