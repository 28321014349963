import React, { useState } from "react";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import Register from "../Register/Register";
import Login from "../Login/Login";
import { useModal } from "../../context/ModalContext";
import { useAuth } from "../../hooks/useAuth";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.registerModalBG}>
      <div className={styles.registerModal}>{children}</div>
    </div>
  );
};

const Header = () => {
  const {
    registerOpened,
    loginOpened,
    handleRegisterOpen,
    handleLoginOpen,
    closeModals,
  } = useModal();

  const { isAuthenticated, userName, loading } = useAuth();
  localStorage.getItem("access_token");
  return (
    <header className={styles.headerBg}>
      <div className={styles.header}>
        <Link to={"/"} className={styles.headerLogo}>
          Anitopia
        </Link>

        <Modal isOpen={registerOpened} onClose={closeModals}>
          <Register onClose={closeModals} />
        </Modal>

        <Modal isOpen={loginOpened} onClose={closeModals}>
          <Login onClose={closeModals} />
        </Modal>

        <div className={styles.headerRight}>
          <nav className={styles.headerNav}>
            <ul>
              <li>
                <Link to={"/"} style={{ color: "#aaa9a7" }}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/"} style={{ color: "#aaa9a7" }}>
                  About
                </Link>
              </li>
              <li>
                <Link to={"/"} style={{ color: "#aaa9a7" }}>
                  Anime
                </Link>
              </li>
              <li>
                <Link to={"/"} style={{ color: "#aaa9a7" }}>
                  Community
                </Link>
              </li>
            </ul>
          </nav>

          {loading ? (
            <div></div>
          ) : isAuthenticated ? (
            <Link to={"/profile"}>
              <div className={styles.headerUserProfile}>
                <span>{userName}</span>
              </div>
            </Link>
          ) : (
            <div className={styles.headerLoginRegestration}>
              <button onClick={handleRegisterOpen}>Sign Up</button>
              <button onClick={handleLoginOpen}>Sign In</button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
