import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [registerOpened, setRegisterIsOpened] = useState(false);
  const [loginOpened, setLoginIsOpened] = useState(false);

  const handleRegisterOpen = () => {
    setRegisterIsOpened(true);
    setLoginIsOpened(false);
  };

  const handleLoginOpen = () => {
    setLoginIsOpened(true);
    setRegisterIsOpened(false);
  };

  const switchToLogin = () => {
    setLoginIsOpened(true);
    setRegisterIsOpened(false);
  };

  const switchToRegister = () => {
    setRegisterIsOpened(true);
    setLoginIsOpened(false);
  };

  const closeModals = () => {
    setRegisterIsOpened(false);
    setLoginIsOpened(false);
  };

  return (
    <ModalContext.Provider
      value={{
        registerOpened,
        loginOpened,
        handleRegisterOpen,
        handleLoginOpen,
        closeModals,
        switchToLogin,
        switchToRegister,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
