import React from "react";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import styles from "./WatchingPage.module.scss";
import narutoBG from "../../images/naruto.jfif";
import { useParams } from "react-router-dom";
import { useScrollToTop } from "../../hooks/useScrollToTop";

const genres = ["Пригоди", "Фантастика", "Бойові мистецтва", "Комедія"];

const WatchingPage = () => {
  useScrollToTop();
  const { id } = useParams();
  return (
    <section>
      <div className={styles.sugestedAnime}>
        <div className={styles.sugestedAnimeText}>
          <h1>Naruto</h1> {id}
          <p>
            Naruto - популярне японське аніме, засноване на серії манґи Масаші
            Кішімото серії манґи Масаші Кісімото. У центрі сюжету - Наруто
            Узумакі, молодий ніндзя з Села Прихованого Листа, який мріє стати
            найсильнішим ніндзя і здобути титул Хокаге, лідера села. Наруто
            спочатку вигнанець через Дев'ятихвостого Лиса, могутнього демона,
            запечатаного в ньому в дитинстві. запечатаного в ньому в дитинстві,
            але з часом він здобуває повагу завдяки своїй рішучості, доброті та
            вмінню ніколи не здаватися.
          </p>
          <div className={styles.sugestedAnimeYear}>
            <h4>Рік :</h4> <span>2002</span>
          </div>
          <div className={styles.sugestedAnimeYear}>
            <h4>Автор :</h4> <span>Масаси Кисимото</span>
          </div>
          <div>
            <h4>Жанри :</h4>
            <div className={styles.watchingPageGenre}>
              {genres.map((item, idx) => (
                <span key={idx}>{item}</span>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.sugestedAnimeImage}>
          <img src={narutoBG} alt="Naruto Background" />
        </div>
      </div>
      <VideoPlayer />
    </section>
  );
};

export default WatchingPage;
