import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Login.module.scss";
import { useModal } from "../../context/ModalContext";

const Login = ({ onClose, onSwitchToRegister }) => {
  const [nickname, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { switchToRegister, closeModals } = useModal();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post("http://127.0.0.1:8000/api/login/", {
        nickname,
        password,
      });

      console.log("Response:", response);
      onClose();

      const { access_token, refresh_token } = response.data;

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <div className={styles.headerRegister}>
      <button onClick={onClose} className={styles.buttonExit}>
        ×
      </button>
      <h2>Log in</h2>
      <form onSubmit={handleSubmit} className={styles.headerForm}>
        <label htmlFor="nickname">Username:</label>
        <input
          type="text"
          id="nickname"
          value={nickname}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
        <button
          className={styles.registerLoginButton}
          onClick={switchToRegister}
        >
          Sign up
        </button>
      </form>
    </div>
  );
};

export default Login;
