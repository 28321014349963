import React from "react";
import styles from "./WelcomePage.module.scss";
import welcomePageBG from "../../images/WelcomeBG.png";
import TopAnime from "../../components/TopAnime/TopAnime";
import { useModal } from "../../context/ModalContext";
import MostVIewed from "../../components/MostViewed/MostViewed";
import { useAuth } from "../../hooks/useAuth";
import SugestedAnime from "../../components/SugestedAnime/SugestedAnime";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.registerModalBG}>
      <div className={styles.registerModal}>{children}</div>
    </div>
  );
};

const WelcomePage = () => {
  const { isAuthenticated, loading } = useAuth(); // POFIKSUTU 2 RENDERA
  const {
    registerOpened,
    loginOpened,
    handleRegisterOpen,
    handleLoginOpen,
    closeModals,
  } = useModal();
  return (
    <>
      {loading ? (
        <div style={{ height: "700px" }}></div>
      ) : isAuthenticated ? (
        <SugestedAnime loading={loading} />
      ) : (
        <section>
          <div className={styles.welcomePageSection}>
            <div className={styles.welcomePageText}>
              <h1>
                Welcome <br /> to Anitopia
              </h1>
              <p>
                Immerse yourself in the world of anime! Discover the latest
                <br />
                releases, fan-favorite series, and exclusive content all in
                <br />
                one place.
              </p>
              <div className={styles.welcomePageButtons}>
                <button onClick={handleRegisterOpen}>Sign Up</button>
                <button onClick={handleLoginOpen}>Sign In</button>
              </div>
            </div>
            <div>
              <img src={welcomePageBG} alt="" />
            </div>
          </div>
        </section>
      )}
      <TopAnime />
      <MostVIewed />
    </>
  );
};

export default WelcomePage;
